import React, { useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import GlobelStyleCss from "./Globel.style";
import MainLayout from "./Layout";
import { _getToken, storeAssigned, CUSTOMER_TYPE } from "./services/Methods/normalMethods";
import { useEffect } from "react";
import withRouter from "./services/HOC/withRouterHOC";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerRefferal, getUserCredentialApi, _getCountryCodeApi } from "./services/Redux/Reducer/CustomerSlice";
import Cookies from 'js-cookie';
import _ from "lodash";
import { _storeCustomerTypes } from "./services/Methods/commonPayload";
import { _getPaymentOptions } from "./services/Redux/Reducer/PaymentSlice";
import useScript from "./services/Methods/UseScript.hook";
import * as Sentry from "@sentry/react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, } from "react-router-dom";
import DialogComponent from "./inputs/DailogComponent";
import ButtonComponent from "./inputs/ButtonCompnent";
import localStorageCall from "./services/Methods/localstorage.hook";
import countryListAllIsoData from "./Components/AllStatesCodeArray";

Sentry.init({
  dsn: "https://e36c947fae550e3bf3c61b18bf86c3e4@o4506423182622720.ingest.sentry.io/4506426428162048",
  // dsn: "https://ea4420469c655bbd48b1518eeb28f4d2@o4506437730500608.ingest.sentry.io/4506437757632512",
  maxBreadcrumbs: 600,
  debug: false,
  environment: process.env.REACT_APP_SENTRY_ENV,
  // maxValueLength: 2000,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [process.env.REACT_APP_SITE_URL, /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    })
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeBreadcrumb: (breadcrumb, hint) => {

    if (breadcrumb.category === 'xhr') {
      const data = {
        ...breadcrumb.data,
        requestBody: hint.xhr.__sentry_xhr_v3__.body,
        response: hint.xhr.response,
        responseUrl: hint.xhr.responseURL
      }
      return { ...breadcrumb, data: data }
    }
    return breadcrumb
  },
  // Performance Monitoring
});


const STORES = ["us", "ca", 'au', 'nz', 'mx', 'ph', 'be', 'ng', 'ch', 'cd', 'cm', 'ke', 'mu', 'gb', 'hk', 'no', 'pr', 'za', 'se'];

function App(props) {
  const [locations, setLocations] = useState(null);
  const [weather, setWeather] = useState(null);
  const [switchStore, setSwitchStore] = useState(false);
  const [countryData, setCountryData] = useState();
  const [countryCodeData, setCountryCodeData] = useState();
  const [selectedStore, setSelectedStore] = useState({ defaultCode: 'us', newCode: 'us' });

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [openMiniCart, setOpenMiniCart] = useState(false);
  const [cartValues, setCartValues] = useState();
  const { location, navigate, params } = props;


  const { alluserData, countryCodes, paymentScript, countyObjects } = useSelector((state) => ({
    alluserData: state?.LoginSlice?.alluserData,
    countryCodes: _.map(state?.CustomerSlice?.countryData, 'countryCode'),
    paymentScript: state?.PaymentSlice?.paymentScript,
    countyObjects: _.map(state?.CustomerSlice?.countryData, (row) => ({ code: row?.countryCode, name: row?.countryName })),
  }));

  useScript(paymentScript);
  useEffect(() => {
    dispatch(_getCountryCodeApi());
  }, []);

  useEffect(() => {
    const CART_VALUES = localStorage.getItem('cartData') ? JSON.parse(localStorage.getItem('cartData')) : [];
    setCartValues(CART_VALUES);
    if ((location?.pathname === '/' || location.pathname?.includes('vercel.app')) && !searchParams.get('ssokey')) {
      window?.location.assign(`/${storeAssigned()}`)
    }
    if (!sessionStorage?.getItem('store')) {
      if (STORES?.includes(params?.store)) {
        sessionStorage.setItem('store', params?.store)
      }
    } else {
      if (sessionStorage.getItem('store') !== params?.store) {
        if (STORES?.includes(params?.store)) {
          sessionStorage.setItem('store', params?.store)
        }
      }
    }
  }, [location?.pathname, openMiniCart]);

  useEffect(() => {
    const DEFUALT_STORE = localStorageCall()?.getSingleItem('store');
    if (!DEFUALT_STORE) {
      if (STORES?.includes(params?.store)) {
        localStorageCall().setItem('store', params?.store)
      }
    } else {
      if (DEFUALT_STORE !== params?.store) {
        if (STORES?.includes(params?.store)) {
          localStorageCall().setItem('store', params?.store);
          setSelectedStore({ defaultCode: params?.store, newCode: params?.store });
        } else {
          setSelectedStore({ defaultCode: DEFUALT_STORE, newCode: DEFUALT_STORE });
        }
      } else {
        setSelectedStore({ defaultCode: DEFUALT_STORE, newCode: DEFUALT_STORE });
      }
    }

  }, [location?.pathname]);

  useEffect(() => {
    const DATASTORE = sessionStorage.getItem('popupTrue');
    // if (!DATASTORE && (_.find(countryListAllIsoData, (r) => _.lowerCase(r?.code) === params?.store))) {
    //   success();
    // }

    if (!DATASTORE && (_.find(countryListAllIsoData, (r) => _.lowerCase(r?.code) === params?.store))) {
      success();
    } else if (DATASTORE && (_.find(countryListAllIsoData, (r) => _.lowerCase(r?.code) === params?.store)) && !_.includes(STORES, params?.store)) {
      success();
    }

  }, []);

  async function success(callback = null) {
    const response = await fetch('https://api.ipify.org/');
    const ip = await response.text();
    if (ip) {
      const CurrentCountry = await fetch(`https://pro.ip-api.com/json/${ip}?key=QzWizLOfUTKbkWQ`);
      const VALUES = await CurrentCountry.json();
      setCountryData(VALUES?.country);
      setCountryCodeData(VALUES?.countryCode);
      if (!_.includes(["us", "usa", 'ca', 'se'], VALUES?.countryCode?.toLowerCase())) {
        sessionStorage.setItem('popupTrue', true);
        setSwitchStore(true);
      }

      if (callback) {
        if (_.includes(STORES, _.lowerCase(VALUES?.countryCode))) {
          callback(VALUES?.countryCode?.toLowerCase());
        } else {
          callback(`us`);
        }
      }
    }
  };

  function checkStore() {
    setSwitchStore(false);
    if (params?.store === location.pathname) {
      if (_.includes(STORES, _.lowerCase(countryCodeData))) {
        navigate(`/${_.lowerCase(countryCodeData)}`);
      } else {
        navigate(`/us`);
      }
    } else {
      navigate(location.pathname);
    }
  };


  useEffect(() => {
    if (!((_.map(countryListAllIsoData, (r) => _.lowerCase(r?.code)))?.includes(params?.store))) {
      success((currentIP) => {
        localStorageCall().setItem('store', currentIP)
        if (searchParams.get('ssokey')) {
          const REDIRECT_PAGE = searchParams.get('redirectTo') ? searchParams.get('redirectTo') : "";
          dispatch(getUserCredentialApi(searchParams.get('ssokey'), navigate, REDIRECT_PAGE));
        } else {
          if (location?.pathname !== '/') {
            dispatch(getCustomerRefferal((data) => {
              if (data?.length > 0) {
                const refferalData = JSON.stringify({ "id": data[0]?.customerId, "name": data[0]?.publicProfile?.fullName, "webalies": data[0]?.webAlias });
                Cookies.set('refferal_link', refferalData, { expires: 60 });
                const REDIRECT_PAGE = searchParams.get('redirectTo') ? searchParams.get('redirectTo') : "";
                window.location.assign(REDIRECT_PAGE ? `/${_.lowerCase(currentIP)}${REDIRECT_PAGE}` : `/${_.lowerCase(currentIP)}`)
              } else {
                navigate(`/${storeAssigned()}`)
              }
            }, params?.store));
          }
        }
      })
    }
  }, [params, searchParams.get('ssokey')]);

  const token = _getToken();
  if (alluserData?.emailAddress) {
    Sentry.setUser({ id: alluserData?.customerId, email: alluserData?.emailAddress });
    Sentry.setTag("userID", alluserData?.customerId);
  }
  useEffect(() => {
    if (token?.token && alluserData?.length === 0) {
      _storeCustomerTypes(dispatch, token?.token, searchParams.get('autoshipId'));
    }
  }, [token?.token])

  let Dates = _.pick(alluserData, ['distributorDate', 'signUpDate', 'entryDate']);



  return (
    <>
      <GlobelStyleCss />
      <div className="App">
        <MainLayout  {...{ token, alluserData, cartValues, selectedStore, setSelectedStore, countryCodes, countyObjects }} >
          <Outlet context={{
            alluserData, Dates, token, cartValues, setCartValues, openMiniCart, setOpenMiniCart, countryCodes
          }} />
        </MainLayout>
        <div className="mini-root-div" onClick={() => setOpenMiniCart(true)}>
          <div onClick={() => setOpenMiniCart(true)} className="fa fa-shopping-cart">
            {cartValues?.length > 0 && <span>{cartValues?.length} </span>}
          </div>
        </div>
      </div>
      <DialogComponent opend={switchStore} handleClose={() => checkStore()} title="" classFor="switchStore">
        <h3>Visiting from {(_.includes(["us", "usa", "uk"], countryCodeData?.countryCode?.toLowerCase())) && "the"}  {countryData}?</h3>
        <p>Find our products in {(_.includes(["us", "usa", "uk"], countryCodeData?.countryCode?.toLowerCase())) && "the"} {countryData} on Navi organics</p>

        <a className="switch-store" href={process.env.REACT_APP_NAVILINK} target="_blank">
          Go to Navi Organics
        </a>
      </DialogComponent>
    </>
  );
}
export default withRouter(App);
