import _ from "lodash";
import ButtonComponent from "../../../inputs/ButtonCompnent";
import EnrollmentSecondStyleCss from "../styles/EnrollmentSecond.style";
import { CUSTOMER_TYPE, storeAssigned } from "../../../services/Methods/normalMethods";
import DialogComponent from "../../../inputs/DailogComponent";
import { useState } from "react";
import TopRelatedProducts from "../../../Components/TopRelatedProducts";
const EnrollmentSecondSection = (props) => {

    const { navigate, scrollId } = props;


    const CARD_CONTENT = [
        { image: require('../assets/DISTRIBUTOR_PROGRAM.jpg'), heading: "Affiliate Program", para: "Get up to 20 % commission based on your purchase", link: "enrollment_signup" },
        { image: require('../assets/LoyaltyProgram.jpg'), heading: "Wholesale Program", para: "Get an extra discount on batch purchase", link: "wholesale_program" },
        { image: require('../assets/LoyaltyProgramTwo.jpeg'), heading: "Rewards Program", para: "Earn points as you shop and use them to get FREE products", link: "reward_program" }
    ];

    const TOP_PRODUCTS = [
        { image: require('../assets/AboutMedicin.png'), heading: "CV Well Immunity Booster", rating: 5, para: "$69.00 — available on subscription from <strong>$59.50</strong> / month" },
        { image: require('../assets/WellnessEssentials.png'), heading: "Wellness Essentials", rating: 5, para: "$75.00 — available on subscription from <strong>$63.75</strong> / month" },
        { image: require('../assets/product1.png'), heading: "Skin Rejuvenation Vitamin C Cream", rating: 5, para: "$40.00 — available on subscription" }
    ];

    const [isDistributor, setIsDistributor] = useState(false);

    function _checkingDistributer(row) {
        if (CUSTOMER_TYPE?.customerType === 'Distributor' && row?.link === "enrollment_signup") {
            setIsDistributor(true)
        } else {
            navigate(`/${storeAssigned()}/${row?.link}`)
        }
    }

    return (
        <EnrollmentSecondStyleCss>
            <div className="enrollment_second_section">
                <div className="first_container_for_second" id={['#affiliate', '#wholesale', '#loyalty'].includes(scrollId) ? scrollId : ''}>
                    <div className="partnershipProgram">
                        <h2>Partnership Programs</h2>
                    </div>
                    <div className="main_cardSection">
                        {_.map(storeAssigned() !== 'se' ? CARD_CONTENT : CARD_CONTENT?.filter(val => val.heading === 'Rewards Program'), (row, index) => (
                            <div className="cardSection" key={index + 1}>
                                <div className="card_mage">
                                    <img src={row?.image} alt="Distributor Program" />
                                </div>
                                <div className="card_content">
                                    <h3>{row?.heading}</h3>
                                    <p>{row?.para}</p>
                                </div>
                                <ButtonComponent onClick={() => _checkingDistributer(row)}>{index !== 2 ? 'Sign up' : 'Rewards'}</ButtonComponent>
                            </div>
                        ))}
                    </div>
                </div>
                <TopRelatedProducts />
            </div>

            <DialogComponent opend={isDistributor} handleClose={() => setIsDistributor(false)} title="Info" classFor="isDistributer">
                <div style={{ padding: "0px 20px 0px" }}>
                    <p> You are already a Distributor please <a href={`/${storeAssigned()}/login`}>login</a></p>
                </div>
            </DialogComponent>
        </EnrollmentSecondStyleCss >
    )
}

export default EnrollmentSecondSection;