import TextFieldComponent from '../../../inputs/TextFieldComponent';
import { RegionDropdown, CountryDropdown } from "react-country-region-selector";
import ButtonComponent from '../../../inputs/ButtonCompnent';
import { Check, Close } from '@mui/icons-material';
import { REFFERAL_VALUES } from '../../../services/Methods/normalMethods';
import { useState } from 'react';
import { IconButton } from '@mui/material';

const RetailInfo = (props) => {
    const { fieldValue, countryState, _validateRefferalFunction, setWebAliesErros, webAliesError, setCountryState, stateError, countryCodes } = props;
    const [isChecked, setIsChecked] = useState(true);

    const InputField = (name, label, type) => {
        return (
            <div className='md-3-field'>
                <TextFieldComponent
                    name={name}
                    type={type ? type : 'text'}
                    label={label}
                    value={fieldValue?.values?.[name]}
                    error={!!(fieldValue?.touched?.[name] && fieldValue?.errors?.[name])}
                    onChange={fieldValue?.handleChange}
                    errorMessage={fieldValue?.errors?.[name]}
                />
                {<>{(name === "phone" && process.env.REACT_APP_OTP_ENABLE === 'true') ? <span>Note: phone number will be used to validate a code in the next step *</span> : ""} </>}
            </div>
        )
    };

    return (
        <div className='container' style={{ marginTop: "100px" }}>
            <div className='row-form'>
                {InputField('firstName', "First Name")}
                {InputField('lastName', "Last Name")}
            </div>
            <div className='row-form'>
                <div className='select hide-country'>
                    {countryCodes?.length > 0 &&
                        <CountryDropdown
                            value={countryState?.country}
                            priorityOptions={['US', 'CA', 'SE']}
                            name="country"
                            whitelist={countryCodes}
                            valueType="short"
                            onChange={(val) => {
                                setCountryState((prv) => ({ ...prv, country: val, countryError: "" }))

                            }
                            } />
                    }
                    {/* {countryState?.country==="" && <p className="error">Please select your country </p>} */}
                    {countryState?.countryError && <p className="error">{countryState?.countryError}</p>}

                </div>

                <div className="select ">
                    <RegionDropdown
                        country={countryState?.country || "US"}
                        disabled={!countryState?.country}
                        value={countryState?.state}
                        name="state"
                        valueType="short"
                        defaultOptionLabel="State"
                        countryValueType="short"
                        onChange={(val) => {
                            setCountryState((prv) => ({ ...prv, state: val, stateError: "" }));

                        }}
                    />
                    {countryState?.stateError && <p className="error">{countryState?.stateError}</p>}
                    {countryState?.stateError ? window.scrollTo(0, 0) : ""}

                    {/* {countryState?.state==="" && <p className="error">Please select your state</p>} */}
                </div>
            </div>
            <div className='row-form'>
                {InputField('address', "Address")}
                {InputField('address_2', "Address 2 (optional) ")}
            </div>
            <div className='row-form'>
                {InputField('city', "City")}
                {InputField('zipcode', "Zip Code")}
            </div>
            <div className='row-form'>
                {InputField('company', "Company Name")}
            </div>
            <div className='row-form'>
                {InputField('phone', "Phone Number", 'phone')}
                <div className='md-3-field'>
                    <div className='' style={{ flex: '100%' }}>
                        <TextFieldComponent
                            name="refferal"
                            type='text'
                            className="w-full"
                            disabled={!!(REFFERAL_VALUES?.webalies || !webAliesError?.changeRefferal)}
                            label={"Referral"}
                            placeholder={"Referral"}
                            value={fieldValue?.values?.refferal}
                            onChange={fieldValue?.handleChange}
                            error={(!!webAliesError?.isRefferalError) || !!fieldValue?.errors.refferal}
                            errorMessage={webAliesError?.isRefferalError || fieldValue?.errors.refferal}
                        />
                    </div>

                    {(REFFERAL_VALUES?.webalies || !webAliesError?.changeRefferal)
                        ?
                        <button type="button" className="change-btn"
                            onClick={() => {
                                fieldValue?.setFieldValue('refferal', fieldValue?.values?.copyrefferal);
                                fieldValue?.setFieldValue('copyrefferal', fieldValue?.values?.refferal);
                                setWebAliesErros((prv) => ({ ...prv, changeRefferal: true, }))
                            }
                            }>
                            Change
                        </button>
                        :
                        <div className='cstm-add-btn-wrap'>
                            <IconButton type='button' className="" onClick={() => _validateRefferalFunction(fieldValue)}><Check /></IconButton>
                            <IconButton type='button' className="" onClick={() => {
                                setWebAliesErros((prv) => ({ ...prv, changeRefferal: false }));
                                // fieldValue.setFieldValue('refferal', REFFERAL_VALUES?.webalies, true);
                                fieldValue?.setFieldValue('refferal', fieldValue?.values?.copyrefferal);
                                fieldValue?.setFieldValue('copyrefferal', fieldValue?.values?.refferal);
                            }}><Close /></IconButton>
                        </div>
                    }
                </div>
            </div>
            <div className='row-form'>
                {InputField('password', "Password", 'password')}
                {InputField('confirm_password', "Confirm Password", 'password')}
            </div>
            <div className='row-form'>
                <div className='checkbox'>
                    <input
                        type='checkbox'
                        id='SmsChecked'
                        // checked={isChecked}
                        defaultChecked={true}
                    />
                    <label htmlFor='SmsChecked'>
                        <span className="checkbox-label-text">I agree to be contacted via email and SMS.</span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default RetailInfo;
